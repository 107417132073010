import {
    ALLOCATION_REQUEST_FORM,
    DELIVERY_DATE_REMINDER_FORM,
    ORDER_CANCELLATION_FORM,
    ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
    VOR_PVI_ORDER_FORM,
    CODED_PARTS_KEYS_FORM,
    OVAL_PLATE_ORDER_FORM,
    SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
    SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
    UNKNOWN_PART_OPENING_REQUEST_FORM,
    SPARE_PARTS_REQUEST_FOR_SUPPORT_FORM,
    ORDER_TICKET_STATUS_NEW,
    ORDER_TICKET_STATUS_IN_PROGRESS_DEALER,
    ORDER_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
    ORDER_TICKET_STATUS_READ_BY_SUBSIDIARY,
    ORDER_TICKET_STATUS_REOPENED,
    ORDER_TICKET_STATUS_CLOSED,
    DOCUMENTATION_TICKET_STATUS_NEW,
    DOCUMENTATION_TICKET_STATUS_IN_PROGRESS_DEALER,
    DOCUMENTATION_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
    DOCUMENTATION_TICKET_STATUS_READ_BY_SUBSIDIARY,
    DOCUMENTATION_TICKET_STATUS_REOPENED,
    DOCUMENTATION_TICKET_STATUS_CLOSED,
    CLAIMS_TICKET_STATUS_NEW,
    CLAIMS_TICKET_STATUS_IN_PROGRESS_DEALER,
    CLAIMS_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
    CLAIMS_TICKET_STATUS_READ_BY_SUBSIDIARY,
    CLAIMS_TICKET_STATUS_REOPENED,
    CLAIMS_TICKET_STATUS_CLOSED,
    CONTEST_CLAIM_STATUS_FORM,
    PROBLEM_FOR_PLACING_CLAIMS_FORM,
    RETURN_CLAIM_FORM,
    STOCK_DEAD_CLAIM_FORM,
    OLD_MATERIAL_CLAIM_FORM,
    DIRECT_FLOW_CLAIM_FORM,
    EV_BATTERY_FORM,
    DOCUMENTATION_TICKET_STATUS_TRANSFERRED,
    DOCUMENTATION_INTERNAL_TICKET_STATUS_NEW,
    DOCUMENTATION_INTERNAL_TICKET_STATUS_CLOSED,
    DOCUMENTATION_INTERNAL_TICKET_STATUS_TRANSFERRED,
    DOCUMENTATION_INTERNAL_TICKET_STATUS_ANSWERED,
    DOCUMENTATION_INTERNAL_TICKET_STATUS_IN_PROGRESS,
    DOCUMENTATION_INTERNAL_TICKET_STATUS_REOPENED,
    ORDER_TICKET_STATUS_TRANSFERRED,
    ORDER_INTERNAL_TICKET_STATUS_NEW,
    ORDER_INTERNAL_TICKET_STATUS_CLOSED,
    ORDER_INTERNAL_TICKET_STATUS_TRANSFERRED,
    ORDER_INTERNAL_TICKET_STATUS_ANSWERED,
    ORDER_INTERNAL_TICKET_STATUS_IN_PROGRESS,
    ORDER_INTERNAL_TICKET_STATUS_REOPENED,
    TROUBLESHOOT_CLAIM_FORM
} from './formConstants';

export const ALL_COUNTRIES_VALUE = 'ALL';
export const SORT_ASC = 'asc';
export const SORT_DESC = 'desc';
export const SORT_NONE = '';
export const REQUEST_PARAM_DELIM = ':';

export const domains = {
    ADMINISTRATION: 'ADMINISTRATION',
    SPARE_PARTS: 'SPARE_PARTS',
    TECHNICAL_DOCUMENTATION: 'TECHNICAL_DOCUMENTATION',
    CLAIMS: 'CLAIMS',
    ASE: 'ASE',
    GENERIC_TICKET: 'GENERIC_TICKET',
};

export const languages = ['bs', 'cs', 'da', 'de', 'en', 'es', 'fr', 'hr', 'hu', 'it', 'nl', 'pl', 'pt', 'ro', 'ru', 'ru_UA', 'sk', 'sl', 'sr', 'sv', 'gsw', 'de_AT', 'es_AR', 'es_MX', 'tr'];

export const countryCurrency = {
    AR: 'ARS',
    CH: 'CHF',
    CZ: 'CZK',
    FI: 'SEK',
    HU: 'HUF',
    MA: 'MAD',
    NO: 'SEK',
    PL: 'PLN',
    RO: 'RON',
    GB: 'GBP',
    RU: 'RUB',
    MX: 'MXN',
    UA: 'UAH',
    TR: 'TRY',
    DEFAULT: 'EUR'
};

export const locations = [
    'cs-CZ',
    'de-DE',
    'en-GB',
    'en-IE',
    'es-ES',
    'fr-FR',
    'it-IT',
    'pl-PL',
    'pt-PT',
    'sl-SI',
    'sv-SE',
    'es-AR',
    'es-MX',
    'uk-UA',
    'sq-XK',
    'tr-TR',
];

export const countryGroups = {
    AR: 'AR',
    AT: 'AT',
    BA: 'BA',
    BE: 'BE',
    CH: 'CH',
    CZ: 'CZ',
    DE: 'DE',
    DK: 'DK',
    DZ: 'DZ',
    EE: 'EE',
    ES: 'ES',
    FI: 'FI',
    FR: 'FR',
    HR: 'HR',
    HU: 'HU',
    IE: 'IE',
    IT: 'IT',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    MA: 'MA',
    ME: 'ME',
    MK: 'MK',
    MX: 'MX',
    NL: 'NL',
    NO: 'NO',
    PL: 'PL',
    PT: 'PT',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    BY: 'BY',
    KZ: 'KZ',
    SE: 'SE',
    SI: 'SI',
    AL: 'AL',
    SK: 'SK',
    GB: 'GB',
    UA: 'UA',
    XK: 'XK',
    IMP: 'IMP',
    IN: 'IN',
    KR: 'KR',
    CO: 'CO',
    BR: 'BR',
    TR: 'TR'
};

export const balticCountryGroups = [
    "EE", "LV", "LT"
]

export const importerCountries = [
    'CM',
    'CV',
    'LK',
    'TD',
    'TW',
    'YT',
    'CG',
    'CY',
    'BJ',
    'GF',
    'DJ',
    'GA',
    'GE',
    'PS',
    'GH',
    'GP',
    'HK',
    'ID',
    'IR',
    'IQ',
    'IL',
    'CI',
    'JO',
    'KE',
    'KW',
    'LB',
    'LR',
    'LY',
    'MG',
    'MW',
    'MY',
    'ML',
    'MT',
    'MQ',
    'MU',
    'MZ',
    'OM',
    'NC',
    'NZ',
    'NE',
    'NG',
    'QA',
    'RE',
    'PM',
    'SA',
    'SN',
    'SC',
    'SG',
    'VN',
    'ZW',
    'SD',
    'TH',
    'TG',
    'AE',
    'UG',
    'EG',
    'TZ',
    'BF',
    'ZM',
    'PF',
    'AO',
    'AU',
    'BH',
    'BN',
    'ZA',
    'PH',
    'MR',
    'SY',
    'TN',
    'GR',
    'IS',
    'ET',
    'UY',
    'BM',
    'GT',
    'BO',
    'CL',
    'PE',
    'FI',
    'NO',
    'JP',
    'RS',
    'MD',
    'BG',
    'IE',
    'BY',
    'KZ',
    'UZ',
    'AM',
    'AZ',
    'MN',
    'EC'
];

export function getDealerFormTypes(data) {
    let result = [];
    const forms = Object.entries(data);

    for(const [formType, value] of forms) {
        if(value === true) {
            result.push(formType)
        }
    }
    return result;
}

export function getEditorFormTypes(domain, data) {
    let result = [];
    const countries = Object.entries(data);

    switch (domain) {
        case domains.SPARE_PARTS: {
            for(const [country, value] of countries) {
                if(value === true) {
                    result.push(...getSparePartsFormTypes(country));
                }
            }
            break;
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            for(const [country, value] of countries) {
                if(value === true) {
                    result.push(...getTechDocFormTypes(country));
                }
            }
            break;
        }
        case domains.CLAIMS: {
            for(const [country, value] of countries) {
                if(value === true) {
                    result.push(...getClaimsFormTypes(country));
                }
            }
            break;
        }
        default: {
            result.push([]);
        }
    }

    return [...new Set(result)].sort();
}

export function getSgEditorFormTypesOptions(domain, data) {
    if (domain === domains.TECHNICAL_DOCUMENTATION) {
        let countries = [];
        let getAllFormTypes = false;

        data && data.forEach(permission =>  {
            if (permission && !permission.isDefault) {
                getAllFormTypes = true;
            } else if (permission && permission.isDefault && permission.groups && permission.groups.length) {
                countries = [...countries, ...permission.groups];
            }
        });

        if (getAllFormTypes) {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM
            ];
        } else {
            let formTypes = [];
            countries.forEach(country => {
                formTypes = [...formTypes, ...getTechDocFormTypes(country)]
            })
            return [...new Set(formTypes)].sort();
        }
    }
    if (domain === domains.SPARE_PARTS) {
        let countries = [];
        let getAllFormTypes = false;

        data && data.forEach(permission =>  {
            if (permission && !permission.isDefault) {
                getAllFormTypes = true;
            } else if (permission && permission.isDefault && permission.groups && permission.groups.length) {
                countries = [...countries, ...permission.groups];
            }
        });

        if (getAllFormTypes) {
            return [
                ALLOCATION_REQUEST_FORM,
                ORDER_CANCELLATION_FORM,
                VOR_PVI_ORDER_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                EV_BATTERY_FORM
            ];
        } else {
            let formTypes = [];
            countries.forEach(country => {
                formTypes = [...formTypes, ...getSparePartsFormTypes(country)]
            })
            return [...new Set(formTypes)].sort();
        }
    }
    console.error(`getSgEditorFormTypes not implemented for domain: ${domain}`);
    return [];
}

export function getSparePartsFormTypes(country) {
    switch (country) {
        case countryGroups.AR: {
            return [
                ALLOCATION_REQUEST_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.AT: {
            return [
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.BA: {
            return [
                ALLOCATION_REQUEST_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                EV_BATTERY_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.BE: {
            return [
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.CH: {
            return [
                ALLOCATION_REQUEST_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.CZ: {
            return [
                ALLOCATION_REQUEST_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.DE: {
            return [
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.DK: {
            return [
                ALLOCATION_REQUEST_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.DZ: {
            return [
            ];
        }
        case countryGroups.EE: {
            return [
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.ES: {
            return [
                ALLOCATION_REQUEST_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.FI: {
            return [
                ALLOCATION_REQUEST_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.FR: {
            return [
                ALLOCATION_REQUEST_FORM,
                ORDER_CANCELLATION_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM,
                EV_BATTERY_FORM
            ];
        }
        case countryGroups.HR: {
            return [
                ALLOCATION_REQUEST_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                EV_BATTERY_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.XK: {
            return [
                ALLOCATION_REQUEST_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.HU: {
            return [
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.IE: {
            return [
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
            ];
        }
        case countryGroups.IT: {
            return [
                ALLOCATION_REQUEST_FORM,
                ORDER_CANCELLATION_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.LT: {
            return [
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.LU: {
            return [
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.LV: {
            return [
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.MA: {
            return [

            ];
        }
        case countryGroups.ME: {
            return [
                ALLOCATION_REQUEST_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.MK: {
            return [
                ALLOCATION_REQUEST_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                EV_BATTERY_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.MX: {
            return [
                ALLOCATION_REQUEST_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                VOR_PVI_ORDER_FORM,
            ];
        }
        case countryGroups.NL: {
            return [
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.NO: {
            return [
                ALLOCATION_REQUEST_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.PL: {
            return [
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.PT: {
            return [
                ALLOCATION_REQUEST_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                EV_BATTERY_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.RO: {
            return [
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.RS: {
            return [
                ALLOCATION_REQUEST_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.RU: {
            return [
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                ORDER_CANCELLATION_FORM,
            ];
        }
        case countryGroups.BY: {
            return [
                ALLOCATION_REQUEST_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.KZ: {
            return [
                ALLOCATION_REQUEST_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.SE: {
            return [
                ALLOCATION_REQUEST_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.SI: {
            return [
                ALLOCATION_REQUEST_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.AL: {
            return [
                ALLOCATION_REQUEST_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                EV_BATTERY_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.SK: {
            return [
                ALLOCATION_REQUEST_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.GB: {
            return [
                ALLOCATION_REQUEST_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                EV_BATTERY_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.UA: {
            return [
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                VOR_PVI_ORDER_FORM,
                ALLOCATION_REQUEST_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
            ];
        }
        case countryGroups.IMP: {
            return [
                ALLOCATION_REQUEST_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM
            ];
        }
        case countryGroups.IN: {
            return [];
        }
        case countryGroups.KR: {
            return [];
        }
        case countryGroups.CO: {
            return [];
        }
        case countryGroups.BR: {
            return [];
        }
        case countryGroups.TR: {
            return [
                VOR_PVI_ORDER_FORM
            ];
        }
        default: {
            return [];
        }
    }
}

export function getTechDocFormTypes(country) {
    switch (country) {
        case countryGroups.AR: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.AT: {
            return [
                CODED_PARTS_KEYS_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.BA: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.BE: {
            return [
                CODED_PARTS_KEYS_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.CH: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
            ];
        }
        case countryGroups.CZ: {
            return [
                CODED_PARTS_KEYS_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.DE: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.DK: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.DZ: {
            return [
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.EE: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.ES: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.FI: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.FR: {
            return [
                CODED_PARTS_KEYS_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.HR: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.XK: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.HU: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.IE: {
            return [
                CODED_PARTS_KEYS_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.IT: {
            return [
                CODED_PARTS_KEYS_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.LT: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.LU: {
            return [
                CODED_PARTS_KEYS_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.LV: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.MA: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.ME: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.MK: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.MX: {
            return [
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.NL: {
            return [
                CODED_PARTS_KEYS_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.NO: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.PL: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.PT: {
            return [
                CODED_PARTS_KEYS_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.RO: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.RS: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.RU: {
            return [
                CODED_PARTS_KEYS_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.BY: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.KZ: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.SE: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.SI: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.AL: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.SK: {
            return [
                CODED_PARTS_KEYS_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.GB: {
            return [
                CODED_PARTS_KEYS_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.UA: {
            return [
                UNKNOWN_PART_OPENING_REQUEST_FORM,
                OVAL_PLATE_ORDER_FORM,
                CODED_PARTS_KEYS_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
            ];
        }
        case countryGroups.IMP: {
            return [
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
            ];
        }
        case countryGroups.IN: {
            return [];
        }
        case countryGroups.KR: {
            return [];
        }
        case countryGroups.CO: {
            return [];
        }
        case countryGroups.BR: {
            return [];
        }
        case countryGroups.TR: {
            return [];
        }
        default: {
            return [];
        }
    }
}

export function getCurrentFormTypes(domain) {
    return getFormTypes(domain).filter(type => type !== SPARE_PARTS_REQUEST_FOR_SUPPORT_FORM);
}

export function getFormTypes(domain) {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return [
                ALLOCATION_REQUEST_FORM,
                DELIVERY_DATE_REMINDER_FORM,
                ORDER_CANCELLATION_FORM,
                ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
                VOR_PVI_ORDER_FORM,
                EV_BATTERY_FORM,
            ];
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return [
                CODED_PARTS_KEYS_FORM,
                OVAL_PLATE_ORDER_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
                SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
                UNKNOWN_PART_OPENING_REQUEST_FORM,
                SPARE_PARTS_REQUEST_FOR_SUPPORT_FORM,
            ];
        }
        case domains.CLAIMS: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM,
                TROUBLESHOOT_CLAIM_FORM
            ];
        }
        default: {
            return [];
        }
    }
}

export function getClaimsFormTypes(country) {
    switch (country) {
        case countryGroups.AR: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.AT: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.BA: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.BE: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.CH: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.CZ: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.DE: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.DK: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                STOCK_DEAD_CLAIM_FORM,
            ];
        }
        case countryGroups.DZ: {
            return [
            ];
        }
        case countryGroups.EE: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.ES: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.FI: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                STOCK_DEAD_CLAIM_FORM,
            ];
        }
        case countryGroups.FR: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM,
                TROUBLESHOOT_CLAIM_FORM
            ];
        }
        case countryGroups.HR: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.XK: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.HU: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.IE: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.IT: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.LT: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.LU: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.LV: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.MA: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.ME: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.MK: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.MX: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.NL: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.NO: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                STOCK_DEAD_CLAIM_FORM,
            ];
        }
        case countryGroups.PL: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.PT: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.RO: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.RS: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.RU: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.BY: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.KZ: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.SE: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                STOCK_DEAD_CLAIM_FORM,
            ];
        }
        case countryGroups.SI: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.AL: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.SK: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.GB: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.UA: {
            return [

            ];
        }
        case countryGroups.IMP: {
            return [
                CONTEST_CLAIM_STATUS_FORM,
                PROBLEM_FOR_PLACING_CLAIMS_FORM,
                RETURN_CLAIM_FORM,
                STOCK_DEAD_CLAIM_FORM,
                OLD_MATERIAL_CLAIM_FORM,
                DIRECT_FLOW_CLAIM_FORM
            ];
        }
        case countryGroups.IN: {
            return [];
        }
        case countryGroups.KR: {
            return [];
        }
        case countryGroups.CO: {
            return [];
        }
        case countryGroups.BR: {
            return [];
        }
        case countryGroups.TR: {
            return [];
        }
        default: {
            return [];
        }
    }
}

export function getFormStates(domain) {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return [
                ORDER_TICKET_STATUS_NEW,
                ORDER_TICKET_STATUS_IN_PROGRESS_DEALER,
                ORDER_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
                ORDER_TICKET_STATUS_READ_BY_SUBSIDIARY,
                ORDER_TICKET_STATUS_REOPENED,
                ORDER_TICKET_STATUS_CLOSED,
                ORDER_TICKET_STATUS_TRANSFERRED,
            ];
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return [
                DOCUMENTATION_TICKET_STATUS_NEW,
                DOCUMENTATION_TICKET_STATUS_IN_PROGRESS_DEALER,
                DOCUMENTATION_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
                DOCUMENTATION_TICKET_STATUS_READ_BY_SUBSIDIARY,
                DOCUMENTATION_TICKET_STATUS_REOPENED,
                DOCUMENTATION_TICKET_STATUS_CLOSED,
                DOCUMENTATION_TICKET_STATUS_TRANSFERRED,
            ];
        }
        case domains.CLAIMS: {
            return [
                CLAIMS_TICKET_STATUS_NEW,
                CLAIMS_TICKET_STATUS_IN_PROGRESS_DEALER,
                CLAIMS_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
                CLAIMS_TICKET_STATUS_READ_BY_SUBSIDIARY,
                CLAIMS_TICKET_STATUS_REOPENED,
                CLAIMS_TICKET_STATUS_CLOSED,
            ]
        }
        default: {
            return [];
        }
    }
}

export function getInternalTicketFormStates(domain) {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return [
                ORDER_INTERNAL_TICKET_STATUS_NEW,
                ORDER_INTERNAL_TICKET_STATUS_CLOSED,
                ORDER_INTERNAL_TICKET_STATUS_TRANSFERRED,
                ORDER_INTERNAL_TICKET_STATUS_ANSWERED,
                ORDER_INTERNAL_TICKET_STATUS_IN_PROGRESS,
                ORDER_INTERNAL_TICKET_STATUS_REOPENED,
            ];
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return [
                DOCUMENTATION_INTERNAL_TICKET_STATUS_NEW,
                DOCUMENTATION_INTERNAL_TICKET_STATUS_CLOSED,
                DOCUMENTATION_INTERNAL_TICKET_STATUS_TRANSFERRED,
                DOCUMENTATION_INTERNAL_TICKET_STATUS_ANSWERED,
                DOCUMENTATION_INTERNAL_TICKET_STATUS_IN_PROGRESS,
                DOCUMENTATION_INTERNAL_TICKET_STATUS_REOPENED,
            ];
        }
        case domains.CLAIMS: {
            return [

            ]
        }
        default: {
            return [];
        }
    }
}

export const modules = {
    ADMINISTRATION: domains.ADMINISTRATION,
    SPARE_PARTS: domains.SPARE_PARTS,
    TECHNICAL_DOCUMENTATION: domains.TECHNICAL_DOCUMENTATION,
    CLAIMS: domains.CLAIMS,
    BVM: 'BVM'
};

export const courtesyCars = {
    AT: [
        'ALL_TYPE_OF_VEHICLES',
    ],
    HU: [
        'PASSENGER_CAR_HU',
        'PASSENGER_CAR_LUX_HU',
        'SCENIC_ZOE_KADJAR_DOKKER_DUSTER_LODGY_HU',
        'LAGUNA_TALISMAN_ESPACE_KOLEOS_HU',
        'TRAFIC_MASTER_HU',
    ],
    PL: [
        'PASSENGER_CAR_PL_TO_21_DAYS',
        'PASSENGER_CAR_PL_OVER_21_DAYS',
        'PASSENGER_CAR_LUX_PL_TO_21_DAYS',
        'PASSENGER_CAR_LUX_PL_OVER_21_DAYS',
        'VAN_PL_TO_21_DAYS',
        'VAN_PL_OVER_21_DAYS',
        'VANS_TO_21_DAYS',
        'VANS_OVER_21_DAYS',
    ],
    LV: [
        'PASSENGER_CAR_PL_TO_21_DAYS',
        'PASSENGER_CAR_PL_OVER_21_DAYS',
        'PASSENGER_CAR_LUX_PL_TO_21_DAYS',
        'PASSENGER_CAR_LUX_PL_OVER_21_DAYS',
        'VAN_PL_TO_21_DAYS',
        'VAN_PL_OVER_21_DAYS',
        'VANS_TO_21_DAYS',
        'VANS_OVER_21_DAYS',
    ],
    LT: [
        'PASSENGER_CAR_PL_TO_21_DAYS',
        'PASSENGER_CAR_PL_OVER_21_DAYS',
        'PASSENGER_CAR_LUX_PL_TO_21_DAYS',
        'PASSENGER_CAR_LUX_PL_OVER_21_DAYS',
        'VAN_PL_TO_21_DAYS',
        'VAN_PL_OVER_21_DAYS',
        'VANS_TO_21_DAYS',
        'VANS_OVER_21_DAYS',
    ],
    EE: [
        'PASSENGER_CAR_PL_TO_21_DAYS',
        'PASSENGER_CAR_PL_OVER_21_DAYS',
        'PASSENGER_CAR_LUX_PL_TO_21_DAYS',
        'PASSENGER_CAR_LUX_PL_OVER_21_DAYS',
        'VAN_PL_TO_21_DAYS',
        'VAN_PL_OVER_21_DAYS',
        'VANS_TO_21_DAYS',
        'VANS_OVER_21_DAYS',
    ],
    BE: [
        'TARIF_B1',
        'TARIF_B2',
        'TARIF_B3',
        'TARIF_B4',
        'TARIF_B5',
        'DACIA',
    ],
    NL: [
        'NL1_2024',
        'NL2_2024',
        'NL3_2024',
        'NL4_2024',
        'NL5_2024',
        'NL6_2024',
    ],
    LU: [
        'TARIF_B1',
        'TARIF_B2',
        'TARIF_B3',
        'TARIF_B4',
        'TARIF_B5',
        'DACIA',
    ],
    PT: [
        'SCENIC',
        'MEGANE',
        'TRAFIC',
        'KANGOO',
        'CLIO',
        'SANDERO',
        'JOGGER',
        'DOKKER_VAN',
    ],
    SI: [
        'VU1',
        'VU2',
        'VP',
    ],
    HR: [
        'VU1',
        'VU2',
        'VP',
    ],
    XK: [
        'VU1',
        'VU2',
        'VP',
    ],
    BA: [
        'VU1',
        'VU2',
        'VP',
    ],
    RS: [
        'VU1',
        'VU2',
        'VP',
    ],
    MK: [
        'VU1',
        'VU2',
        'VP',
    ],
    ME: [
        'VU1',
        'VU2',
        'VP',
    ],
    AL: [
        'VU1',
        'VU2',
        'VP',
    ],
    CH: [
        'RENAULT',
        'DACIA',
        'UTILITY_VEHICLES_RENAULT',
        'UTILITY_VEHICLES_DACIA',
    ],
    DE: [
        'PASSENGER_CAR_DE',
        'PASSENGER_CAR_LUX_DE',
        'VAN_DE',
        'DACIA'
    ],
    ES: [
        'VP_PVU',
        'GVU',
    ],
    IT: [
        'RENTER',
        'COURTESY_CAR',
        'DEALER_PARK',
    ],
    SE: [
        'RENAULT_MODEL',
        'DACIA_MODEL',
        'VAN_SE_NEW',
    ],
    FI: [
        'TARIF_A',
        'TARIF_B',
        'TARIF_C',
        'VAN_SE',
        'DACIA',
    ],
    NO: [
        'TARIF_A',
        'TARIF_B',
        'TARIF_C',
        'VAN_SE',
        'DACIA',
    ],
    DK: [
        'RENAULT_MODEL',
        'DACIA_MODEL',
        'VAN_DK',
    ],

    FR: [
        'TWINGO',
        'GAMME_DACIA',
        'CLIO',
        'KANGOO_VU',
        'KANGOO_VP',
        'TRAFIC_5_6M3',
        'TRAFIC_9_PLACES',
        'ESPACE_4',
        'ESPACE_5',
        'ALASKAN',
        'TALISMAN',
        'SCENIC',
        'MEGANE',
        'MASTER',
        'TWINZY_B',
    ],
    CZ: [
        'A_TWINGO_CLIO_CAPTUR',
        'B_MEGANE_KANGOO_FLU',
        'C_SCENIC_ZOE_KADJAR',
        'D_TALISMAN_ESPACE',
        'VU_TRAFIC_MASTER'
    ],
    SK: [
        'A_TWINGO_CLIO_CAPTUR',
        'B_MEGANE_KANGOO_FLU',
        'C_SCENIC_ZOE_KADJAR',
        'D_TALISMAN_ESPACE',
        'VU_TRAFIC_MASTER'
    ],
    GB: [
        "ALPINE_CAR",
        "ARKANA_CAR",
        "ARKANA_PHAS1",
        "AUSTRAL_CAR",
        "CAPTUR_CAR",
        "CLIO_CAR",
        "CLIO_PHAS1",
        "DUSTER_CAR",
        "ESPACE_CAR",
        // "FLUENCE_CAR",
        "JOGGER_CAR",
        "JOGGER_PHAS1",
        "KADJAR_CAR",
        "KANGOO_CAR",
        "KANGOO_VAN",
        "KOLEOS_CAR",
        "LAGUNA_CAR",
        "LOGAN_MCV_CAR",
        "MASTER_VAN",
        "MEGANE_CAR",
        "MODUS_CAR",
        "SANDERO_CAR",
        "SANDERO_STEPWAY_CAR",
        "SCENIC_CAR",
        "TRAFIC_VAN",
        "TRAFIC_PASSENGER_CAR",
        "TWINGO_CAR",
        // "TWIZZY_CAR",
        "WIND_CAR",
        "ZOE_CAR",
        "ZOE_VAN_VAN"
    ],
    IE: [
        'ALPINE_CAR',
        'ARKANA_CAR',
        'AUSTRAL_CAR',
        'CAPTUR_CAR',
        'CLIO_CAR',
        'DUSTER_CAR',
        'ESPACE_CAR',
        'FLUENCE_CAR',
        'JOGGER_CAR',
        'KADJAR_CAR',
        'KANGOO_CAR',
        'KANGOO_VAN',
        'KOLEOS_CAR',
        'LAGUNA_CAR',
        'LOGAN_MCV_CAR',
        'MASTER_VAN',
        'MEGANE_CAR',
        'MODUS_CAR',
        'SANDERO_CAR',
        'SANDERO_STEPWAY_CAR',
        'SCENIC_CAR',
        'TRAFIC_VAN',
        'TRAFIC_PASSENGER_CAR',
        'TWINGO_CAR',
        'TWIZZY_CAR',
        'WIND_CAR',
        'ZOE_CAR',
        'ZOE_VAN_VAN',
    ],
    TR: [
        'CLIO_V_MANUAL',
        'CLIO_V_AUTOMATIC',
        'MEGANE_SEDAN_MANUAL',
        'MEGANE_SEDANE_AUTOMATIC',
        'CAPTUR_MANUAL',
        'CAPTUR_AUTOMATIC',
        'KADJAR_MANUAL',
        'KADJAR_AUTOMATIC',
        'AUSTRAL_AUTOMATIC',
        'MEGANE_E_TECH_AUTOMATIC',
        'KOLEOS_AUTOMATIC',
        'TALISMAN_AUTOMATIC',
        'KANGOO_EXPRESS_MANUAL',
        'TALIANT_MANUAL',
        'TALIANT_AUTOMATIC',
        'ZOE_AUTOMATIC',
        'DUSTER_4X4_MANUAL',
        'DUSTER_AUTOMATIC',
        'SANDERO_MANUAL',
        'SANDERO_AUTOMATIC',
        'JOGGER_MANUAL',
        'JOGGER_AUTOMATIC'
    ]
};

export const dailyRates = {
    AT: {
        ALL_TYPE_OF_VEHICLES: '30',
    },
    HU: {
        PASSENGER_CAR_HU: '7000',
        PASSENGER_CAR_LUX_HU: '8000',
        SCENIC_ZOE_KADJAR_DOKKER_DUSTER_LODGY_HU: '10000',
        LAGUNA_TALISMAN_ESPACE_KOLEOS_HU: '13000',
        TRAFIC_MASTER_HU: '20000',
    },
    PL: {
        PASSENGER_CAR_PL_TO_21_DAYS: '128',
        PASSENGER_CAR_PL_OVER_21_DAYS: '102',
        PASSENGER_CAR_LUX_PL_TO_21_DAYS: '148',
        PASSENGER_CAR_LUX_PL_OVER_21_DAYS: '118',
        VAN_PL_TO_21_DAYS: '214',
        VAN_PL_OVER_21_DAYS: '171',
        VANS_TO_21_DAYS: '297',
        VANS_OVER_21_DAYS: '237'
    },
    LV: {
        PASSENGER_CAR_PL_TO_21_DAYS: '23',
        PASSENGER_CAR_PL_OVER_21_DAYS: '18',
        PASSENGER_CAR_LUX_PL_TO_21_DAYS: '29',
        PASSENGER_CAR_LUX_PL_OVER_21_DAYS: '23',
        VAN_PL_TO_21_DAYS: '40',
        VAN_PL_OVER_21_DAYS: '32',
        VANS_TO_21_DAYS: '52',
        VANS_OVER_21_DAYS: '42'
    },
    LT: {
        PASSENGER_CAR_PL_TO_21_DAYS: '23',
        PASSENGER_CAR_PL_OVER_21_DAYS: '18',
        PASSENGER_CAR_LUX_PL_TO_21_DAYS: '29',
        PASSENGER_CAR_LUX_PL_OVER_21_DAYS: '23',
        VAN_PL_TO_21_DAYS: '40',
        VAN_PL_OVER_21_DAYS: '32',
        VANS_TO_21_DAYS: '52',
        VANS_OVER_21_DAYS: '42'
    },
    EE: {
        PASSENGER_CAR_PL_TO_21_DAYS: '23',
        PASSENGER_CAR_PL_OVER_21_DAYS: '18',
        PASSENGER_CAR_LUX_PL_TO_21_DAYS: '29',
        PASSENGER_CAR_LUX_PL_OVER_21_DAYS: '23',
        VAN_PL_TO_21_DAYS: '40',
        VAN_PL_OVER_21_DAYS: '32',
        VANS_TO_21_DAYS: '52',
        VANS_OVER_21_DAYS: '42'
    },
    BE: {
        TARIF_B1: '34.65',
        TARIF_B2: '45.05',
        TARIF_B3: '38.25',
        TARIF_B4: '62.10',
        TARIF_B5: '88.43',
        DACIA: '34.65',
    },
    NL: {
        NL1_2024: '27.50',
        NL2_2024: '40.50',
        NL3_2024: '48.50',
        NL4_2024: '40.50',
        NL5_2024: '60.00',
        NL6_2024: '27.50',
    },
    LU: {
        TARIF_B1: '34.65',
        TARIF_B2: '45.05',
        TARIF_B3: '38.25',
        TARIF_B4: '62.10',
        TARIF_B5: '88.43',
        DACIA: '34.65',
    },
    PT: {
        SCENIC: '37',
        MEGANE: '33',
        TRAFIC: '29',
        KANGOO: '21',
        CLIO: '22',
        SANDERO: '21',
        JOGGER: '30',
        DOKKER_VAN: '20'
    },
    SI: {
        VU1: '30',
        VU2: '20',
        VP: '30',
    },
    HR: {
        VU1: '30',
        VU2: '20',
        VP: '30',
    },
    XK: {
        VU1: '30',
        VU2: '20',
        VP: '30',
    },
    RS: {
        VU1: '30',
        VU2: '20',
        VP: '30',
    },
    MK: {
        VU1: '30',
        VU2: '20',
        VP: '30',
    },
    ME: {
        VU1: '30',
        VU2: '20',
        VP: '30',
    },
    BA: {
        VU1: '30',
        VU2: '20',
        VP: '30',
    },
    AL: {
        VU1: '30',
        VU2: '20',
        VP: '30',
    },
    CH: {
        RENAULT: '50',
        DACIA: '40',
        UTILITY_VEHICLES_RENAULT: '70',
        UTILITY_VEHICLES_DACIA: '40',
    },
    DE: {
        PASSENGER_CAR_DE: '35',
        PASSENGER_CAR_LUX_DE: '39',
        VAN_DE: '56',
        DACIA: '22',
    },
    ES: {
        VP_PVU: '22',
        GVU: '44',
    },
    IT: {
        RENTER: '30.00',
        COURTESY_CAR: '1.00',
        DEALER_PARK: '30.00',
    },
    SE: {
        RENAULT_MODEL: '22.00',
        DACIA_MODEL: '18.00',
        VAN_SE_NEW: '27.00',
    },
    FI: {
        TARIF_A: '285.00',
        TARIF_B: '285.00',
        TARIF_C: '285.00',
        VAN_SE: '447.00',
        DACIA: '285.00',
    },
    NO: {
        TARIF_A: '285.00',
        TARIF_B: '285.00',
        TARIF_C: '285.00',
        VAN_SE: '447.00',
        DACIA: '285.00',
    },
    DK: {
        RENAULT_MODEL: '24.00',
        DACIA_MODEL: '20.00',
        VAN_DK: '27.00',
    },
    FR: {
        TWINGO: '32.00',
        GAMME_DACIA: '33.00',
        CLIO: '33.00',
        KANGOO_VU: '35.00',
        KANGOO_VP: '33.00',
        TRAFIC_5_6M3: '46.00',
        TRAFIC_9_PLACES: '47.00',
        ESPACE_4: '47.00',
        ESPACE_5: '58.00',
        ALASKAN: '47.00',
        TALISMAN: '47.00',
        SCENIC: '47.00',
        MEGANE: '37.00',
        MASTER: '46.00',
        TWINZY_B: '19.95',
    },
    CZ: {
        A_TWINGO_CLIO_CAPTUR: '700.00',
        B_MEGANE_KANGOO_FLU: '800.00',
        C_SCENIC_ZOE_KADJAR: '1000.00',
        D_TALISMAN_ESPACE: '1300.00',
        VU_TRAFIC_MASTE: '2000.00'
    },
    SK: {
        A_TWINGO_CLIO_CAPTUR: '28.00',
        B_MEGANE_KANGOO_FLU: '32.00',
        C_SCENIC_ZOE_KADJAR: '40.00',
        D_TALISMAN_ESPACE: '52.00',
        VU_TRAFIC_MASTE: '80.00'
    },
    GB: {
        ALPINE_CAR: '65.00',
        ARKANA_CAR: '32.00',
        ARKANA_PHAS1: '32.00',
        AUSTRAL_CAR: '42.00',
        CAPTUR_CAR: '32.00',
        CLIO_CAR: '30.00',
        CLIO_PHAS1: '30',
        DUSTER_CAR: '42.00',
        ESPACE_CAR: '42.00',
        // FLUENCE_CAR: '25.00',
        JOGGER_CAR: '42.00',
        JOGGER_PHAS1: '42',
        KADJAR_CAR: '42.00',
        KANGOO_CAR: '42.00',
        KANGOO_VAN: '42.00',
        KOLEOS_CAR: '42.00',
        LAGUNA_CAR: '30.00',
        LOGAN_MCV_CAR: '30.00',
        MASTER_VAN: '54.00',
        MEGANE_CAR: '30.00',
        MODUS_CAR: '30.00',
        SANDERO_CAR: '30.00',
        SANDERO_STEPWAY_CAR: '30.00',
        SCENIC_CAR: '42.00',
        TRAFIC_VAN: '42.00',
        TRAFIC_PASSENGER_CAR: '54.00',
        TWINGO_CAR: '30.00',
        // TWIZZY_CAR: '20.00',
        WIND_CAR: '30.00',
        ZOE_CAR: '30.00',
        ZOE_VAN_VAN: '42.00',
    },
    IE: {
        ALPINE_CAR: '45.00',
        ARKANA_CAR: '40.00',
        AUSTRAL_CAR: '40.00',
        CAPTUR_CAR: '30.00',
        CLIO_CAR: '30.00',
        DUSTER_CAR: '40.00',
        ESPACE_CAR: '40.00',
        FLUENCE_CAR: '30.00',
        JOGGER_CAR: '40.00',
        KADJAR_CAR: '40.00',
        KANGOO_CAR: '40.00',
        KANGOO_VAN: '40.00',
        KOLEOS_CAR: '40.00',
        LAGUNA_CAR: '30.00',
        LOGAN_MCV_CAR: '30.00',
        MASTER_VAN: '50.00',
        MEGANE_CAR: '30.00',
        MODUS_CAR: '30.00',
        SANDERO_CAR: '30.00',
        SANDERO_STEPWAY_CAR: '30.00',
        SCENIC_CAR: '40.00',
        TRAFIC_VAN: '40.00',
        TRAFIC_PASSENGER_CAR: '50.00',
        TWINGO_CAR: '30.00',
        TWIZZY_CAR: '30.00',
        WIND_CAR: '30.00',
        ZOE_CAR: '30.00',
        ZOE_VAN_VAN: '40.00',
    },
    TR: {
        CLIO_V_MANUAL: '1150.00',
        CLIO_V_AUTOMATIC: '1450.00',
        MEGANE_SEDAN_MANUAL: '1450.00',
        MEGANE_SEDANE_AUTOMATIC: '1750.00',
        CAPTUR_MANUAL: '1500.00',
        CAPTUR_AUTOMATIC: '1800.00',
        KADJAR_MANUAL: '2000.00',
        KADJAR_AUTOMATIC: '2300.00',
        AUSTRAL_AUTOMATIC: '2450.00',
        MEGANE_E_TECH_AUTOMATIC: '2450.00',
        KOLEOS_AUTOMATIC: '2450.00',
        TALISMAN_AUTOMATIC: '2450.00',
        KANGOO_EXPRESS_MANUAL: '1250.00',
        TALIANT_MANUAL: '1250.00',
        TALIANT_AUTOMATIC: '1450.00',
        ZOE_AUTOMATIC: '1750.00',
        DUSTER_4X4_MANUAL: '1500.00',
        DUSTER_AUTOMATIC: '1800.00',
        SANDERO_MANUAL: '1150.00',
        SANDERO_AUTOMATIC: '1350.00',
        JOGGER_MANUAL: '1450.00',
        JOGGER_AUTOMATIC: '1750.00'
    }
};


export const rootCauses = [
  'WRONG_OR_NOT_COMPLETE',
  'MISSING_DOCUMENTATION',
  'INCLUDE_OR_NOT_INCLUDE_ERROR',
  'APPLICATION_CODE_ERROR',
  'MISSING_OR_WRONG_LINK',
  'MISSING_OR_NOT_COMPLETE_ILLUSTRATION',
  'EXPLODED_VIEW_NOT_CLEAR',
  'DOCUMENTATION_IS_CORRECT',
  'PUBLICATION_DELAY',
  'TRANSLATION_ERROR',
];

export const customerEffects = [
    'MISSING',
    'NOT_CORRECT',
    'WRONG_TRANSLATION',
    'EASY_BASKET_NOT_COMPLETED'
];

export const customerRisk = [
    'NONE',
    'SAFETY',
    'QUALITY'
];

export const internalStatuses = {
    PL: [
        'CONSULTATION',
        'REQUEST_CHECK_ON_SHELF',
        'REQUEST_FOR_OPENING',
        'CLOSED'
    ],
    BE: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    NL: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    LU: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    CZ: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    SK: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    PT: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    MA: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    SI: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    HR: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    XK: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    RS: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    MK: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    MX: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    ME: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    BA: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    AL: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    AR: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    RU: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    BY: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    KZ: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    AT: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    HU: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    CH: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_CODE',
        'SOLUTION',
        'DIVERS',
        'REQUEST_OPENING_FOR_SALE',
    ],
    DE: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    ES: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    IT: [
        'DIVERS',
        'E_KEYS',
        'GAR_RAISED_TO_FRANCE',
        'REQUEST_OPENING_FOR_SALE',
        'STOCK_CHECK_REQUESTED',
    ],
    SE: [
        'CONTROL_STOCK_CDE',
        'CONTROL_STOCK_DLPA',
        'GAR_RAISED_TO_FRANCE',
        'KISTA',
        'PENDING_E_KEYS',
        'REQUEST_OPENING_FOR_SALE',
        'PART_ORDERED',
        'DIVERS',
    ],
    FI: [
        'CONTROL_STOCK_CDE',
        'CONTROL_STOCK_DLPA',
        'GAR_RAISED_TO_FRANCE',
        'KISTA',
        'PENDING_E_KEYS',
        'REQUEST_OPENING_FOR_SALE',
        'PART_ORDERED',
        'DIVERS',
    ],
    NO: [
        'CONTROL_STOCK_CDE',
        'CONTROL_STOCK_DLPA',
        'GAR_RAISED_TO_FRANCE',
        'KISTA',
        'PENDING_E_KEYS',
        'REQUEST_OPENING_FOR_SALE',
        'PART_ORDERED',
        'DIVERS',
    ],
    DK: [
        'CONTROL_STOCK_CDE',
        'CONTROL_STOCK_DLPA',
        'GAR_RAISED_TO_FRANCE',
        'KISTA',
        'PENDING_E_KEYS',
        'REQUEST_OPENING_FOR_SALE',
        'PART_ORDERED',
        'DIVERS',
    ],
    DZ: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    GB: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
        'E_KEYS',
        'PENDING_ICS',
    ],
    UA: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    IE: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
        'E_KEYS',
        'PENDING_ICS',
    ],

    FR: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
    RO: [
        'GAR_RAISED_TO_FRANCE',
        'STOCK_CHECK_REQUESTED',
        'REQUEST_OPENING_FOR_SALE',
    ],
};

export const immobilizedVehicle = [
    'YES',
    'NO',
];

export const priorityTreatment = [
    'YES',
    'NO',
];

export const VEHICLE_SAFETY_IMPACT = "VEHICLE_SAFETY_IMPACT"
export const OTS_QUALITY_BLOCAGE = "OTS_QUALITY_BLOCAGE"
export const ACTIS_REPAIR_METHOD = "ACTIS_REPAIR_METHOD"

export const priorityCases = [
    VEHICLE_SAFETY_IMPACT,
    OTS_QUALITY_BLOCAGE,
    ACTIS_REPAIR_METHOD
];

export const PRIORITY_SRC = 'SRC';
export const PRIORITY_VOR = 'VOR';
export const PRIORITY_SRC_VOR = 'SRC + VOR';

export const priorityType = [
    PRIORITY_SRC,
    PRIORITY_VOR,
    PRIORITY_SRC_VOR,
];

export const requestForPartner = [
    'YES',
    'NO',
];

export const documentationUpdate = [
    'YES',
    'NO',
];

export const requestForPartnerList = [
    'RENAULT_TRUCKS',
    'NISSAN',
    'MITSUBISHI',
    'OPEL_VAUXHALL',
    'FIAT',
    'DAIMLER',
    'LACOUR',
];

export const problemCategoryList = [
    "PART_AND_VEHICLE_NON_COMPLIANT",
    "WAY_OF_SELLING",
    "HISTO_FILE_ERROR_UPDATE",
    "ILLUSTRATION_UPDATE",
    "ASBOM_UPDATE_NEEDED",
    "ASBOM_OK_STOCK_CHECK_NEEDED",
    "REPAIR_ENGINEERING_IS_NOT_CONCERNED",
    "PART_NUMBER_AVAILABLE_IN_NDIALOGYS",
    "PART_NUMBER_DEPLETED_WITHOUT_REPLACEMENT",
    "SERIAL_LIFE_SPECIFICATION_ERROR",
    "VALIDATED_REPLACEMENT",
    "REFUSED_REPLACEMENT",
    "ALTERNATIVE_OFFER",
    "IT_SOFTWARE_ISSUE",
    "MISSING_INFORMATION",
];

export const storeCheckResultsRootCauseList = [
    "PACKAGING_ERROR",
    "CONFORM_PART",
    "DLU_OVERDUE",
    "ORDER_ERROR",
    "WRONG_PART_SERVED",
    "MELTED_REFERENCES",
    "WRONG_LABELLING",
    "DAMAGED_PART",
    "NOT_CONFORM_PART"
];

export const VISUAL_STORE_CHECK = 'VISUAL_STORE_CHECK'
export const REQUEST_TO_OPEN_FOR_SALE = 'REQUEST_TO_OPEN_FOR_SALE'
export const PART_DOC_REQUEST = 'PART_DOC_REQUEST'
export const REF_REPLACEMENT_REQUEST = 'REF_REPLACEMENT_REQUEST'
export const PRICE_REQUEST = 'PRICE_REQUEST'

export const internalTicketRequestType = [
    VISUAL_STORE_CHECK,
    REQUEST_TO_OPEN_FOR_SALE,
    PART_DOC_REQUEST,
    REF_REPLACEMENT_REQUEST,
    PRICE_REQUEST
]

export function getInternalFormTypes(domain) {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return [
                VISUAL_STORE_CHECK,
                REQUEST_TO_OPEN_FOR_SALE,
                PART_DOC_REQUEST,
                REF_REPLACEMENT_REQUEST,
                PRICE_REQUEST
            ];
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return [
                VISUAL_STORE_CHECK,
                REQUEST_TO_OPEN_FOR_SALE,
                PART_DOC_REQUEST,
                REF_REPLACEMENT_REQUEST,
                PRICE_REQUEST
            ];
        }
        case domains.CLAIMS: {
            return [

            ];
        }
        default: {
            return [];
        }
    }
}

export const problemTypes = {
    PL: [
        'KEY_CARD_LOST_STOLEN',
        'KEY_PLIP_DOESNT_EXIST',
        'NEW_KEY_CODE_IN_DB',
        'CODED_PART_DOESNT_FIT',
        'CODED_PARTS_ORDERING_PROBLEM',
        'CODED_PARTS_OTHER_PROBLEMS',
    ],
    BE: [
        'KEY_PLIP_STOLEN',
        'KEY_DOESNT_WORK',
        'DEMAND_CODES',
        'KEY_PLIP_SPARE_PART',
        'DIALOGYS_ERROR',
        'DELIVERED_COLLECTION_INCOMPLETE',
    ],
    NL: [
        'KEY_PLIP_STOLEN',
        'KEY_DOESNT_WORK',
        'DEMAND_CODES',
        'KEY_PLIP_SPARE_PART',
        'DIALOGYS_ERROR',
        'DELIVERED_COLLECTION_INCOMPLETE',
    ],
    LU: [
        'KEY_PLIP_STOLEN',
        'KEY_DOESNT_WORK',
        'DEMAND_CODES',
        'KEY_PLIP_SPARE_PART',
        'DIALOGYS_ERROR',
        'DELIVERED_COLLECTION_INCOMPLETE',
    ],
    PT: [
        'KEY_PLIP_STOLEN',
        'KEY_DOESNT_WORK',
        'DEMAND_CODES',
        'KEY_PLIP_SPARE_PART',
        'DIALOGYS_ERROR',
        'DELIVERED_COLLECTION_INCOMPLETE',
        'DATE_REQUEST_FOR_UNRECEIVED_KEY',
    ],
    MA: [
        'KEY_PLIP_STOLEN',
        'KEY_DOESNT_WORK',
        'DEMAND_CODES',
        'KEY_PLIP_SPARE_PART',
        'DIALOGYS_ERROR',
        'DELIVERED_COLLECTION_INCOMPLETE',
        'DATE_REQUEST_FOR_UNRECEIVED_KEY',
    ],
    SI: [
        'KEY_PLIP_STOLEN',
        'KEY_DOESNT_WORK',
        'DEMAND_CODES',
        'KEY_PLIP_SPARE_PART',
        'DIALOGYS_ERROR',
        'DELIVERED_COLLECTION_INCOMPLETE',
        'DATE_REQUEST_FOR_UNRECEIVED_KEY',
    ],
    BA: [
        'KEY_PLIP_STOLEN',
        'KEY_DOESNT_WORK',
        'DEMAND_CODES',
        'KEY_PLIP_SPARE_PART',
        'DIALOGYS_ERROR',
        'DELIVERED_COLLECTION_INCOMPLETE',
        'DATE_REQUEST_FOR_UNRECEIVED_KEY',
    ],
    HR: [
        'KEY_PLIP_STOLEN',
        'KEY_DOESNT_WORK',
        'DEMAND_CODES',
        'KEY_PLIP_SPARE_PART',
        'DIALOGYS_ERROR',
        'DELIVERED_COLLECTION_INCOMPLETE',
        'DATE_REQUEST_FOR_UNRECEIVED_KEY',
    ],
    XK: [
        'KEY_PLIP_STOLEN',
        'KEY_DOESNT_WORK',
        'DEMAND_CODES',
        'KEY_PLIP_SPARE_PART',
        'DIALOGYS_ERROR',
        'DELIVERED_COLLECTION_INCOMPLETE',
        'DATE_REQUEST_FOR_UNRECEIVED_KEY',
    ],
    RS: [
        'KEY_PLIP_STOLEN',
        'KEY_DOESNT_WORK',
        'DEMAND_CODES',
        'KEY_PLIP_SPARE_PART',
        'DIALOGYS_ERROR',
        'DELIVERED_COLLECTION_INCOMPLETE',
        'DATE_REQUEST_FOR_UNRECEIVED_KEY',
    ],
    MK: [
        'KEY_PLIP_STOLEN',
        'KEY_DOESNT_WORK',
        'DEMAND_CODES',
        'KEY_PLIP_SPARE_PART',
        'DIALOGYS_ERROR',
        'DELIVERED_COLLECTION_INCOMPLETE',
        'DATE_REQUEST_FOR_UNRECEIVED_KEY',
    ],
    ME: [
        'KEY_PLIP_STOLEN',
        'KEY_DOESNT_WORK',
        'DEMAND_CODES',
        'KEY_PLIP_SPARE_PART',
        'DIALOGYS_ERROR',
        'DELIVERED_COLLECTION_INCOMPLETE',
        'DATE_REQUEST_FOR_UNRECEIVED_KEY',
    ],
    MX: [
        'DATE_REQUEST_FOR_UNRECEIVED_KEY',
        'KEY_DOESNT_WORK',
        'REQUEST_FOR_PART_NUMBER_INFORMATION',
        'DIALOGYS_ERROR',
    ],
    AL: [
        'KEY_PLIP_STOLEN',
        'KEY_DOESNT_WORK',
        'DEMAND_CODES',
        'KEY_PLIP_SPARE_PART',
        'DIALOGYS_ERROR',
        'DELIVERED_COLLECTION_INCOMPLETE',
        'DATE_REQUEST_FOR_UNRECEIVED_KEY',
    ],
    AR: [
        'KEY_PLIP_STOLEN',
        'KEY_DOESNT_WORK',
        'DEMAND_CODES',
        'KEY_PLIP_SPARE_PART',
        'DIALOGYS_ERROR',
        'DELIVERED_COLLECTION_INCOMPLETE',
        'DATE_REQUEST_FOR_UNRECEIVED_KEY',
    ],
    RU: [
        'KEY_PLIP_STOLEN',
        'KEY_DOESNT_WORK',
        'REQUEST_FOR_PART_NUMBER_INFORMATION',
        'DIALOGYS_ERROR',
        'DELIVERED_COLLECTION_INCOMPLETE',
    ],
    BY: [
        'KEY_PLIP_STOLEN',
        'KEY_DOESNT_WORK',
        'DIALOGYS_ERROR',
        'DELIVERED_COLLECTION_INCOMPLETE',
    ],
    KZ: [
        'KEY_PLIP_STOLEN',
        'KEY_DOESNT_WORK',
        'DIALOGYS_ERROR',
        'DELIVERED_COLLECTION_INCOMPLETE',
    ],
    AT: [
        'DATE_REQUEST_FOR_UNRECEIVED_KEY',
        'KEY_DOESNT_WORK',
        'DEMAND_CODES',
        'KEY_PLIP_SPARE_PART',
        'DIALOGYS_ERROR',
        'DELIVERED_COLLECTION_INCOMPLETE',
    ],
    CH: [
        'KEY_DOESNT_WORK',
        'DEMAND_CODES',
    ],
    ES: [
        'KEY_PLIP_STOLEN',
        'DATE_REQUEST_FOR_UNRECEIVED_KEY',
        'KEY_DOESNT_WORK',
        'DEMAND_CODES',
        'KEY_PLIP_SPARE_PART',
        'DIALOGYS_ERROR',
        'DELIVERED_COLLECTION_INCOMPLETE',
    ],
    IT: [
        'KEY_PLIP_STOLEN',
        'DEMAND_CODES',
    ],
    SE: [
        'KEY_PLIP_STOLEN',
        'KEY_DOESNT_WORK',
        'DEMAND_CODES',
        'DELIVERED_COLLECTION_INCOMPLETE',
    ],
    FI: [
        'KEY_PLIP_STOLEN',
        'KEY_DOESNT_WORK',
        'DEMAND_CODES',
        'DELIVERED_COLLECTION_INCOMPLETE',
    ],
    NO: [
        'KEY_PLIP_STOLEN',
        'KEY_DOESNT_WORK',
        'DEMAND_CODES',
        'DELIVERED_COLLECTION_INCOMPLETE',
    ],
    DK: [
        'KEY_PLIP_STOLEN',
        'KEY_DOESNT_WORK',
        'DEMAND_CODES',
        'DELIVERED_COLLECTION_INCOMPLETE',
    ],
    FR: [
        'IMPOSSIBILITY_TO_PLACE_AN_ORDER',
        'ORDER_CANCELLATION',
        'NONCOMPLIANCE',
        'MODIFICATION_OF_BVM',
        'IMPOSSIBILITY_TO_PROGRAM',
        'FOLLOW_UP_OF_CLAIMS',
        'DEMAND_CODES',
        'SPECIAL_REQUEST',
    ],
    GB: [
        'KEY_PLIP_STOLEN',
        'KEY_DOESNT_WORK',
        'DEMAND_CODES',
        'REQUEST_FOR_PART_NUMBER_INFORMATION',
        'DIALOGYS_ERROR',
        'DELIVERED_COLLECTION_INCOMPLETE',
    ],
    UA: [
        'KEY_PLIP_STOLEN',
        'KEY_DOESNT_WORK',
        'REQUEST_FOR_PART_NUMBER_INFORMATION',
        'DIALOGYS_ERROR',
        'DELIVERED_COLLECTION_INCOMPLETE',
        'DEMAND_CODES',
    ],
    IE: [
        'KEY_PLIP_STOLEN',
        'KEY_DOESNT_WORK',
        'DEMAND_CODES',
        'REQUEST_FOR_PART_NUMBER_INFORMATION',
        'DIALOGYS_ERROR',
        'DELIVERED_COLLECTION_INCOMPLETE',
    ],
    DE: [
        'KEY_PLIP_STOLEN',
        'DATE_REQUEST_FOR_UNRECEIVED_KEY',
        'KEY_DOESNT_WORK',
        'DEMAND_CODES',
        'KEY_PLIP_SPARE_PART',
        'DIALOGYS_ERROR',
        'DELIVERED_COLLECTION_INCOMPLETE'
    ]
};

export const batteryProblemTypes = {
    AL:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    AR:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    AT:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    BA:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    BE:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    CZ:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    DE:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    DK:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    EE:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    ES:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    FI:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    FR:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    GB:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    UA:[

    ],
    HR:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    XK:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    CH:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    IE:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    IMP:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    IT:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    LT:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    LU:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    LV:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    MA:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    ME:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    MK:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    NL:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    NO:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    PL:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    PT:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    RO:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    RS:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    RU:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    SE:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    SI:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
    SK:[
        'ORDERING_CONFIRMATION',
        'FOLLOW_UP',
        'OTHER',
    ],
};

export const NEW_BATTERY = 'NEW_BATTERY';
export const SECOND_HAND_BATTERY = 'SECOND_HAND_BATTERY';

export const batteryTypes = [
    NEW_BATTERY,
    SECOND_HAND_BATTERY,
];

export const YES = 'YES';
export const NO = 'NO';
export const PRESIDENT = 'PRESIDENT';

export const secondHandStock = [
    YES,
    NO,
];

export const srcRequestType = [
    NO,
    YES,
    PRESIDENT
];

export const requestOfCourtesyCarType = [
    NO,
    YES
];

export const PROVIDER = "PROVIDER"
export const RECEIVER = "RECEIVER"

export const userStatusType = [
    PROVIDER,
    RECEIVER
]

export const DHL = "DHL"
export const FLASH = "FLASH"
export const OTHERS = "OTHERS"

export const transportProviderType = [
    DHL,
    FLASH,
    OTHERS
]

export const DELAY_CREDIT = "DELAY_CREDIT"
export const DELAY_INVOICE = "DELAY_INVOICE"
export const TROUBLESHOOTING_NOT_RECEIVED = "TROUBLESHOOTING_NOT_RECEIVED"

export const claimType = [
    DELAY_CREDIT,
    DELAY_INVOICE,
    TROUBLESHOOTING_NOT_RECEIVED,
    OTHERS
]

export const prodValues = {
    PL: [
        'STO',
        'URG',
        'PVI',
    ],
    BE: [
        'STO',
        'URG',
        'VOR',
    ],
    NL: [
        'STO',
        'URG',
        'VOR',
    ],
    LU: [
        'STO',
        'URG',
        'VOR',
    ],
    PT: [
        'STO',
        'PRO',
        'URG',
        'VOR',
    ],
    MA: [
        'STO',
        'PRM',
        'URG',
        'PVI',
    ],
    SI: [
        'STO',
        'PRM',
        'URG',
        'PVI',
    ],
    HR: [
        'STO',
        'PRM',
        'URG',
        'PVI',
    ],
    XK: [
        'STO',
        'PRM',
        'URG',
        'PVI',
    ],
    RS: [
        'STO',
        'PRM',
        'URG',
        'PVI',
    ],
    MK: [
        'STO',
        'PRM',
        'URG',
        'PVI',
    ],
    ME: [
        'STO',
        'PRM',
        'URG',
        'PVI',
    ],
    MX: [
        'STO',
        'PRM',
        'URG',
        'PVI',
    ],
    BA: [
        'STO',
        'PRM',
        'URG',
        'PVI',
    ],
    AL: [
        'STO',
        'PRM',
        'URG',
        'PVI',
    ],
    AR: [
        'STO',
        'URG',
        'PVI',
    ],
    AT: [
        'STO',
        'URG',
        'VOR',
    ],
    HU: [
        'STO',
        'URG',
        'VOR',
    ],
    DE: [
        'STO',
        'PRM',
        'URG',
        'PVI',
    ],
    ES: [
        'STO',
        'PRM',
        'URG',
        'PVI',
    ],
    IT: [
        'STO',
        'PRM',
        'URG',
        'VOR',
    ],
    SE: [
        'STO',
        'URG',
        'VOR',
    ],
    FI: [
        'STO',
        'URG',
        'VOR',
    ],
    NO: [
        'STO',
        'URG',
        'VOR',
    ],
    DK: [
        'STO',
        'URG',
        'VOR',
    ],
    GB: [
        'STO',
        'URG',
        'PVI'
    ],
    UA: [
        'STO',
        'PRO',
        'URG',
        'PVI',
    ],
    IE: [
        'STO',
        'URG',
        'PVI'
    ],
    FR: [
        '740',
        '240',
        '280',
        '830',
        '430',
        '460',
        '630',
        '245',
        '435',
        '735',
    ],
    RO: [
        'STO',
        'PRO',
        'URG',
        'VOR',
    ],
    RU: [
        'STO',
        'URG',
        'PVI',
    ],
    CZ: [
        'STO',
        'URG',
        'PVI/VOR',
    ],
    SK: [
        'STO',
        'URG',
        'PVI/VOR',
    ],
    TR: [
        'STO',
        'URG',
        'VOR',
    ],
};

export const carLocations = {
    PL: [
        'OWN_WORKSHOP',
        'MRA',
    ],
    LV: [
        'OWN_WORKSHOP',
        'MRA',
    ],
    LT: [
        'OWN_WORKSHOP',
        'MRA',
    ],
    EE: [
        'OWN_WORKSHOP',
        'MRA',
    ],
    BE: [
        'OWN_WORKSHOP',
    ],
    NL: [
        'OWN_WORKSHOP',
    ],
    LU: [
        'OWN_WORKSHOP',
    ],
    PT: [
        'OWN_WORKSHOP',
        'AGENT',
        'MRA',
        'COUNTER',
    ],
    MA: [
        'OWN_WORKSHOP',
        'AGENT',
        'MRA',
        'COUNTER',
    ],
    SI: [
        'OWN_WORKSHOP',
        'AGENT',
    ],
    HR: [
        'OWN_WORKSHOP',
        'AGENT',
    ],
    XK: [
        'OWN_WORKSHOP',
        'AGENT',
    ],
    RS: [
        'OWN_WORKSHOP',
        'AGENT',
    ],
    MK: [
        'OWN_WORKSHOP',
        'AGENT',
    ],
    ME: [
        'OWN_WORKSHOP',
        'AGENT',
    ],
    MX: [
        'AGENT',
        'MRA',
        'COUNTER',
    ],
    BA: [
        'OWN_WORKSHOP',
        'AGENT',
    ],
    AL: [
        'OWN_WORKSHOP',
        'AGENT',
    ],
    AT: [
        'OWN_WORKSHOP',
        'MRA',
    ],
    HU: [
        'OWN_WORKSHOP',
        'MRA',
    ],
    CH: [
        'AGENT',
    ],
    DE: [
        'OWN_WORKSHOP',
        'AGENT',
        'MRA',
        'COUNTER',
    ],
    ES: [
        'OWN_WORKSHOP',
        'AGENT',
        'MRA',
        'COUNTER',
    ],
    IT: [
        'OWN_WORKSHOP',
        'MRA',
    ],
    SE: [
        'OWN_WORKSHOP',
    ],
    FI: [
        'OWN_WORKSHOP',
    ],
    NO: [
        'OWN_WORKSHOP',
    ],
    DK: [
        'OWN_WORKSHOP',
    ],
    GB: [
        'OWN_WORKSHOP',
    ],
    UA: [
        'AGENT',
        'COUNTER',
    ],
    IE: [
        'OWN_WORKSHOP',
    ],

    FR: [
        'OWN_WORKSHOP',
        'AGENT',
        'MRA',
    ],
    RO: [
        'OWN_WORKSHOP',
    ],
    RU: [
        'Agent',
        'Counter',
    ],
    AR: [
        'AGENT',
        'MRA',
    ],
    CZ: [
        'OWN_WORKSHOP',
        'AGENT',
    ],
    SK: [
        'OWN_WORKSHOP',
        'AGENT',
    ],
    TR: [
        'OWN_WORKSHOP',
    ],
};

export const suppliers = {
    ES: [
        'MATRA',
        'RENAULT_DACIA',
        'ALPINE'
    ],
};

export const automaticClaimsResponseOptions = {};

export const modelAnswersDisplayAreaOptions = [
    'DEALER_NOTES',
    'INTERNAL_NOTES'
];
